
.background{
  
  width: 100%;
  height: 100vh;
  background-image: url('https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80');
  background-attachment: fixed;  /* this is used to fixed background image and scrolling anything on the page */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}



/* Basic styles for columns */
.columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  padding: 20px;
  flex-wrap: wrap;
  
  border-right: 1px solid #eaeaea;
  /* min-width: 150px; */
  box-sizing: border-box;
  min-width: 250px;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }
}

.Apps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
}

.columnn {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #eaeaea;
  box-sizing: border-box;
  min-width: 200px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
}


.test {
 
  display: inline-block;
  transition: margin-left 0.3s ease; /* Apply transition to margin-left property */
}

.test:hover {
  
  margin-left: 10px;/* Move the element 20px to the right when hovered */
  
}





