

img {
  
    max-width: 100%;
    vertical-align: middle;
  }
  
  .cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .cards_item {
    display: flex;
    padding: 1rem;
  }
  

  @media all and (max-width : 446px) {
     .dist{
      display: flex;
      flex-direction: row;
      justify-content: center;
     }
  
  }
  @media (min-width:27.8rem)  and (max-width: 48rem) {
    .cards_item {
      max-width: 333px;
      width: 70%;
      
    }
  }
  @media (min-width: 48rem) and (max-width: 62rem) {
    .cards_item {
      width: 50%;
    }
  }
  
  @media (min-width: 88rem) {
    .cards_item {
      
      width: 25%;
    }
  }
  @media (min-width: 62rem) and (max-width: 87.5rem) {
    .cards_item {
       
        width: 33%;
    }
}

  
  .card {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .card_content {
    padding: 1rem;
    background: linear-gradient(to bottom left, #052e66 100%, #FFC39E 100%);
  }
  
  .card_title {
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin: 0px;
  }
  
  
  .zoom {
 
    transition: transform 0.3s ease; /* Apply transition to transform property */
  }
  
  .zoom:hover {
    transform: scale(1.02); /* Scale the element to 110% when hovered */
  }