 /*.navbar-fixed {


position: fixed;
top: 0;

width: 100%;
}




/* Dropdown Button #04AA6D
.dropbtn {
      background-color: #052e66;
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
    }
    
    /* The container <div> - needed to position the dropdown content 
    .dropdown {
      position: relative;
      display: inline-block;
    }
    
    /* Dropdown Content (Hidden by Default) 
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
    }
    
    /* Links inside the dropdown 
    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    
    /* Change color of dropdown links on hover 
    .dropdown-content a:hover {background-color: #ddd;}
    
    /* Show the dropdown menu on hover 
    .dropdown:hover .dropdown-content {display: block;}
    
    /* Change the background color of the dropdown button when the dropdown content is shown 
    .dropdown:hover .dropbtn {background-color: #07002b;} */













/* 
    h1 {
      margin-top: 100px;
      text-align: center;
      font-size: 40px;
      line-height: 70px;
      font-family: 'roboto', sans-serif;
    }
    #container {
      margin: 0 auto;
      max-width: 890px;
    }
    nav {
      margin: 0;
      padding: 0;
      background-color: #052e66;
    }


    #logo {
      display: block;
      padding: 0 30px;
      float: left;
      font-size: 20px;
      line-height: 60px;




       nav:after {
      content: "";
      display: table;
      clear: both;
    }

    } */

    


    .toggle, [id^=drop] {
      display: none;
     } 
  
    nav ul {
      float: left;
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
    }
    
    nav ul li {
      margin: 0px;
      display: inline-block;
     
      background-color: #052e66;
    }
    
    nav a {
      display: block;
      padding: 0 15px;
      color: white;
      font-size: 15px;
      line-height: 50px;
      text-decoration: none;
    }
    
    nav ul li ul li:hover { background: #07002b;
    
    }
    
    nav ul a:hover { background-color: #07002b; }
    
    nav ul ul {
      display: none;
      position: absolute;
      top: 50px;
    }
    
    nav ul li:hover > ul { display: inherit; }
    
    nav ul ul li {
      width: 200px;
      float: none;
      display: list-item;
      position: relative;
    }
    
    nav ul ul ul li {
      position: relative;
      top: -50px;
      left: 200px;
    }
    
    li > a:after { content: ' '; }
    
    li > a:only-child:after { content: ''; }
    

    
    @media all and (max-width : 768px) {
    
     
      nav { margin: 0; }

      .togg{
        font-size:13px;
      }
     
      
      .toggle + a,
       .menu { display: none; }
      
       .toggle {
        display: block;
        background-color: #052e66;
        padding: 0 20px;
        color: #FFF;
        font-size: 13px;

        line-height: 50px;
        text-decoration: none;
        border: none;
      }
      
      .toggle:hover { background-color: #07002b; }
     
      
      [id^=drop]:checked + ul { display: block; }
      
      nav ul li  {
        display: block;
        width: 80vw;
      }
      
      nav ul ul .toggle,
       nav ul ul a { padding: 0 40px; }


       nav ul ul ul .toggle{
        padding: 0 50px;

       }
       
      
      
      nav ul ul ul a { padding-left:  60px; }
     
      
      nav a:hover,
       nav ul ul ul a { background-color: #07002b; }
      
      nav ul li ul li .toggle,
       nav ul ul a { background-color: #212121; }
      
      nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
      }
      
      nav ul ul li:hover > ul,
      nav ul li:hover > ul { display: none; }
      
      nav ul ul li {
        display: block;
        width: 80vw;
      }
      
      nav ul ul ul li { position: static;
      
      }
      }
      
      @media all and (max-width : 330px) {
      
      nav ul li {
        display: block;
        width: 80vw;
      }
      
      } 
     
     /* Navbar.css   double Column */
     @media all and (min-width : 992px) {
     nav ul ul ul {
      max-height: 600px;
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      min-width: 200px; /* Adjust as needed */
      white-space: nowrap; /* Prevent wrapping */
      column-count: 2;
      column-gap: .5px; /* Set the number of columns */
    }
    
    nav ul ul ul li {
      position: relative;
      top: 0;
      left: 0;
    }
    nav ul li:hover > ul ul ul {
      display: block;
    }
    nav ul ul ul {
      /* ... */
      top: 0; /* Adjust as needed */
      left: 100%; /* Adjust as needed */
    }
            

  }


      @media only screen and (max-width: 992px) and (min-width: 769px)  {
        nav { margin: 0; }

      .togg{
        font-size:13px;
      }
     
      
      .toggle + a,
       .menu { display: none; }
      
       .toggle {
        display: block;
        background-color: #052e66;
        padding: 0 20px;
        color: #FFF;
        font-size: 13px;

        line-height: 50px;
        text-decoration: none;
        border: none;
      }
      
      .toggle:hover { background-color: #07002b; }
     
      
      [id^=drop]:checked + ul { display: block; }
      
      nav ul li  {
        display: block;
        width: 80vw;
      }
      
      nav ul ul .toggle,
       nav ul ul a { padding: 0 40px; }


       nav ul ul ul .toggle{
        padding: 0 50px;

       }
       
      
      
      nav ul ul ul a { padding-left:  60px; }
     
      
      nav a:hover,
       nav ul ul ul a { background-color: #07002b; }
      
      nav ul li ul li .toggle,
       nav ul ul a { background-color: #212121; }
      
      nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
      }
      
      nav ul ul li:hover > ul,
      nav ul li:hover > ul { display: none; }
      
      nav ul ul li {
        display: block;
        width: 80vw;
      }
      
      nav ul ul ul li { position: static;
      
      }
      }

      @media only screen and (max-width: 1200px) and (min-width: 992px)  {
        .fsize{
          font-size: 12px;
        }

      }

     /* @media only screen and (max-width: 1199px) and (min-width: 769px)  {
        nav { margin: 0; }
     
      
      .toggle + a,
       .menu { display: none; }
      
       .toggle {
        display: block;
        background-color: #052e66;
        padding: 0 20px;
        color: #FFF;
        font-size: 18px;
        
        
        line-height: 60px;
        text-decoration: none;
        border: none;
      }
      
      .toggle:hover { background-color: #07002b; }
      
      [id^=drop]:checked + ul { display: block; }
      
      nav ul li {
        display: block;
        width: 100%;
      }
      
      nav ul ul .toggle,
       nav ul ul a { padding: 0 40px; }
      
      nav ul ul ul a { padding: 0 80px; }
      
      nav a:hover,
       nav ul ul ul a { background-color: #07002b; }
      
      nav ul li ul li .toggle,
       nav ul ul a { background-color: #212121; }
      
      nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
      }
      
      nav ul ul li:hover > ul,
      nav ul li:hover > ul { display: none; }
      
      nav ul ul li {
        display: block;
        width: 100%;
      }
      
      nav ul ul ul li { position: static;
      
      }







      } */
  
  
  
  
  
   
  
  
  
  
  
  
  
    
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
   
      /* @media all and (max-width : 500px) {
    
        #logo {
          display: block;
          padding: 0;
          width: 100%;
          text-align: center;
          float: none;
        }
        
        nav { margin: 0; }
        
        .toggle + a,
         .menu { display: none; }
        
        .toggle {
          display: block;
          background-color: #052e66;
          padding: 0 20px;
          color: #FFF;
          font-size: 22px;
          font-weight:bold;
          line-height: 60px;
          text-decoration: none;
          border: none;
        }
        
        .toggle:hover { background-color: #07002b; }
        
        [id^=drop]:checked + ul { display: block; }
        
        nav ul li {
          display: block;
          width: 100%;
        }
        
        nav ul ul .toggle,
         nav ul ul a { padding: 0 40px; }
        
        nav ul ul ul a { padding: 0 80px; }
        
        nav a:hover,
         nav ul ul ul a { background-color: #07002b; }
        
        nav ul li ul li .toggle,
         nav ul ul a { background-color: #212121; }
        
        nav ul ul {
          float: none;
          position: static;
          color: #ffffff;
        }
        
        nav ul ul li:hover > ul,
        nav ul li:hover > ul { display: none; }
        
        nav ul ul li {
          display: block;
          width: 100%;
        }
        
        nav ul ul ul li { position: static;
        
        }
        }
        
        @media all and (max-width : 330px) {
        
        nav ul li {
          display: block;
          width: 94%;
        }
        
        } 
    
    
    
    
    
      */
    
    
    
    
    
    
    
      
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
     








   