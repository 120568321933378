
@media only screen and (max-width:768px){
    .footer-center{
        display: flex;
        flex-direction: column;
        
        align-items: center;

    }
    .text-cen{
        text-align: center;
    }
}